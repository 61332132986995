import 'zenscroll';
import axios from 'axios';
//import 'materialize-css';
import '@materializecss/materialize'
import AOS from 'aos';
 
window.AOS = AOS;
window.axios = axios;

var mmApp = {
    onScroll: function() {
        var top = (window.pageYOffset || mmDomEls.doc.scrollTop) - (mmDomEls.doc.clientTop || 0);

        if (top >= 430) {
            mmDomEls.$nav.classList.add('active');
        } else {
            mmDomEls.$nav.classList.remove('active');
        }
    },
    throttle: function(fn, delay) {
        var last = undefined;
        var timer = undefined;

        return function() {
            var now = +new Date();

            if (last && now < last + delay) {
                clearTimeout(timer);

                timer = setTimeout(function() {
                    last = now;
                    fn();
                }, delay);
            } else {
                last = now;
                fn();
            }
        }
    }
};

var mmDomEls = {};
//var mmFuncs = {};

mmDomEls.doc            = window.document;
mmDomEls.$body          = mmDomEls.doc.getElementsByTagName('body')[0];
mmDomEls.$nav           = mmDomEls.doc.getElementsByTagName('nav')[0];
mmDomEls.$contactFrm    = mmDomEls.doc.getElementById('contactForm');
mmDomEls.$responseWrap  = mmDomEls.doc.getElementById('xhrResponse');
mmDomEls.$preloader     = mmDomEls.doc.querySelectorAll('.preloader-wrapper')[0];
mmDomEls.$slider        = mmDomEls.doc.getElementById('mmHomeSlider');
mmDomEls.$author_switch = mmDomEls.doc.querySelector("body.articles select#authors");
mmDomEls.$sidenav       = mmDomEls.doc.querySelectorAll('.sidenav');
mmDomEls.$multiselects  = mmDomEls.doc.querySelectorAll('.mm-multiselect');

M.AutoInit();

window.addEventListener('scroll', mmApp.throttle(mmApp.onScroll, 60));

window.addEventListener('DOMContentLoaded', function (e) {
  
    // informs CSS that JS is enabled
    mmDomEls.$body.classList.remove('no-js');
    
    // setup mobile slide-out menu
    mmDomEls.sidenav_inst = M.Sidenav.init(mmDomEls.$sidenav, { edge:'right' });
    
    if (mmDomEls.$slider) { 
        var mmSliderCycle = function() {
            mmDomEls.$mmSliderImgs = mmDomEls.doc.querySelectorAll("body.home .carousel-item.active .mm-SuccessStory-imgWrap");

            [...mmDomEls.$mmSliderImgs].forEach(($elm) => {
                $elm.style.backgroundImage = `url("${$elm.dataset.src}")`;
                $elm.classList.remove('scale-out');
            });
        };   
        
        mmDomEls.mmSlider = M.Carousel.init(mmDomEls.$slider, {onCycleTo:mmSliderCycle, shift:50, fullWidth: true, indicators: true});
    }

    // author switcher in Articles section
    if (mmDomEls.$author_switch) {
        mmDomEls.$author_switch.addEventListener('change', function(e) {
            if (this.value.length) {
                window.location = 'articles.php?author=' + this.value;
            } else {
                window.location = 'articles.php';
            }
        });
    }

    // hide carets at phone width in materialize multiselects when populated
    if (mmDomEls.$multiselects) {
        mmDomEls.$multiselects.forEach(function(item) {
            item.addEventListener('change', function() {
                var inst = M.FormSelect.getInstance(item);
                if (inst.isMultiple) {    
                    if (inst.getSelectedValues().length >= 1 ) {
                        inst.wrapper.classList.add('hide-caret');
                    } else {
                        inst.wrapper.classList.remove('hide-caret');
                    }
                }
            }); 
        });
    }
    // compensate for fixed header on mbti page
    // TODO - probably use this everywhere instead of just mbti
    // if (mmDomEls.$body.classList.contains('mbti')) {
    //     var defaultDuration = 777; // ms
    //     var edgeOffset = 110; // px
    //     zenscroll.setup(defaultDuration, edgeOffset);
    // }

    //contact form
    if (mmDomEls.$contactFrm) {

        mmDomEls.$contactFrm.addEventListener('submit', function(e) {
            e.preventDefault();
            sender(mmDomEls.$contactFrm);
            mmDomEls.$body.classList.add('u-xhr-pending');
            mmDomEls.$preloader.classList.add('active');
        });

        var sender = function sender($contactFrm) {
           // var formData = new FormData(mmDomEls.$contactFrm);

            axios.post('xhr/xhr.php', {
                name_and_location: typeof mmDomEls.$contactFrm.name_and_location !== 'undefined' ? mmDomEls.$contactFrm.name_and_location.value : '',
                message: mmDomEls.$contactFrm.message.value,
                email: mmDomEls.$contactFrm.email.value,
                honeypot: mmDomEls.$contactFrm.mm_name.value,
                mm_ip: mmDomEls.$contactFrm.mm_ip.value
            }).then(function(response) {

                mmDomEls.$preloader.classList.remove('active');
                // scroll to top of page
                window.scrollTo(0, 0);

                // success
                if (response.data.status === 'valid') {
                    mmDomEls.$contactFrm.classList.add('hide');
                }
                // validation fail
                if (response.data.status === 'invalid') {
                    mmDomEls.$responseWrap.classList.add('mm-ValidationErrors');
                }

                mmDomEls.$responseWrap.innerHTML = response.data.message;
                mmDomEls.$responseWrap.classList.remove('hide');
                //console.log(response);

            }).catch(function(error) {
                mmDomEls.$preloader.classList.remove('active');
                //console.log(error);
            });
        };
    }   
});
